/**
 * This is the main application component that serves as the entry point for the entire application.
 *
 * It handles routing using React Router, loading WebAssembly (Wasm) resources, and rendering different
 * components based on the current route. The component also displays a loading message while Wasm is loading.
 *
 * @component
 * @example
 * // Import the App component in your application
 * import App from './App';
 *
 * // Render the App component in your application
 * ReactDOM.render(<App />, document.getElementById('root'));
 */
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Container, Stack } from "react-bootstrap";
import QRCode from "react-qr-code";
import { useFetchAuthIdUrlMutation, useFetchUserConfigMutation, useStartJtIDNDemoTransactionMutation } from "./api/api.ts";
import { RootState } from "./redux/store.ts";
import { setUserObject } from "./redux/slices/user.slice.ts";
import IpqsTracker from "./components/IpqsTracker/IpqsTracker.tsx";
import IDNDemoRouter from "./routers/IDNDemoRouter.tsx";
import LogoAndTimer from "./components/LogoAndTimer/LogoAndTimer.tsx";

const IDNDemoApp = () => {
    const { search } = useLocation();
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(search);
    const dispatch = useDispatch();
    const [fetchUserConfig, userConfig] = useFetchUserConfigMutation();
    const [fetchAuthIdUrl, authId] = useFetchAuthIdUrlMutation();
    const [startJtIDNDemoTransactionMutation, startJtIDNDemoTransactionMutationData] = useStartJtIDNDemoTransactionMutation();
    const idnSiteToken = useSelector((state: RootState) => state.demo.tokenUUID);
    const { authIdUrl: userAuthIdUrl, captureRequirements } = useSelector((state: RootState) => state.user);

    const [showQRCode, setShowQRCode] = React.useState(false);
    const { t } = useTranslation();

    const language = urlParams.get("language");
    const defaultEnvVersion = window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_EXPERIENCE;

    React.useEffect(() => {
        let obj = {};
        obj = { ...obj, ...{ routerVersion: defaultEnvVersion } };
        if (language) {
            obj = { ...obj, ...{ language } };
        }
        if (idnSiteToken) {
            obj = { ...obj, ...{ token: idnSiteToken } };
        } else {
            const newIdnSiteToken = crypto.randomUUID();
            obj = {
                ...obj,
                ...{
                    token: newIdnSiteToken, // add a fake token so the authid url comes back correctly
                },
            };
        }

        obj = { ...obj, ...{ captureRequirements: ["front", "barcode", "selfie"] } };
        dispatch(setUserObject(obj));

        startJtIDNDemoTransactionMutation({ urlLanguage: language }).then(() => {
            fetchUserConfig({ token: idnSiteToken });
            if (!userAuthIdUrl) {
                fetchAuthIdUrl({});
            }
        });
    }, []);

    React.useEffect(() => {
        if (!authId.isUninitialized && !authId.isLoading && !authId.isError && authId.isSuccess) {
            dispatch(setUserObject({ authIdUrl: authId?.data?.payload?.authIdUrl }));
        }
    }, [authId]);

    // if user refreshes on any page, navigate them back to the landing page
    React.useEffect(() => {
        const entries = performance.getEntriesByType("navigation") as PerformanceNavigationTiming[];
        if (entries[0].type === "reload") {
            navigate("/");
        }
    }, []);

    // show QR code for up to but not including lg devices
    React.useEffect(() => {
        if (window && window.innerWidth > 1200) {
            setShowQRCode(true);
        }
    }, []);

    return (
        <div className='App'>
            {showQRCode ? (
                <Container>
                    <LogoAndTimer />
                    <Stack gap={3} className='align-items-center mt-5'>
                        <p className='text-center'>{t("app.qrCode")}</p>
                        <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: 250, width: "100%" }}
                            value={window.location.href}
                            viewBox={`0 0 256 256`}
                        />
                    </Stack>
                </Container>
            ) : (
                <>
                    <IDNDemoRouter />
                    {idnSiteToken && !userConfig.isError && !userConfig.isUninitialized && !userConfig.isLoading && (
                        <IpqsTracker transactionId={idnSiteToken} />
                    )}
                </>
            )}
        </div>
    );
};

export default IDNDemoApp;
