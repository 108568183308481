import React from "react";

import IntellicheckLogo from "./assets/Intellicheck/IntellicheckLogo.svg";
import UHaulLogo from "./assets/UHaul/UHaulLogo.svg";
import YahooLogo from "./assets/Yahoo/YahooLogo.png";
import TruistLogo from "./assets/Truist/TruistLogo.svg";
import FirstAmericanLogo from "./assets/FirstAmericanTitle/FirstAmericanTitleLogo.png";
import SoFiLogo from "./assets/SoFi/SoFiLogo.png";
import GreenskyLogo from "./assets/Greensky/GreenskyLogo.svg";
import KeyBankLogo from "./assets/KeyBank/KeyBankLogo.svg";
import HIDGlobalLogo from "./assets/HIDGlobal/HIDGlobalLogo.png";
import DomaLogo from "./assets/Doma/DomaLogo.svg";
import FlexitiLogo from "./assets/Flexiti/FlexitiLogo.png";
import UBSBankLogo from "./assets/UBSBank/UBSLogo.svg";
import DCBankLogo from "./assets/DCBank/DCBankLogo.png";
import PNCBankLogo from "./assets/PNCBank/PNCBankLogo.png";
import EarlyWarningLogo from "./assets/EarlyWarning/EarlyWarningLogo.svg";
import AmericanFirstFinanceLogo from "./assets/AmericanFirstFinance/AmericanFirstFinanceLogo.png";
import TransUnionLogo from "./assets/TransUnion/TransUnionLogo.svg";
import CapitalOneLogo from "./assets/CapitalOne/CapitalOneLogo.svg";
import BellMobilityLogo from "./assets/BellMobility/BellMobilityLogo.png";
import CitiBankLogo from "./assets/CitiBank/CitiBankLogo.png";
import BestBuyLogo from "./assets/BestBuy/BestBuyLogo.png";
import ProgressiveLeasingLogo from "./assets/ProgressiveLeasing/ProgressiveLeasingLogo.png";
import SevenHundredCreditLogo from "./assets/SevenHundredCredit/SevenHundredCreditLogo.png";
import MetaLogo from "./assets/Meta/MetaLogo.svg";
import BoeingEmployeeCreditUnionLogo from "./assets/BoeingEmployeeCreditUnion/BoeingEmployeeCreditUnionLogo.png";
import NavyFederalCreditUnionLogo from "./assets/NavyFederalCreditUnion/NavyFederalCreditUnionLogo.png";
import CommissionBeforeClosingLogo from "./assets/CommissionBeforeClosing/CommissionBeforeClosingLogo.png";
import AmericaFirstCreditUnionLogo from "./assets/AmericaFirstCreditUnion/AmericaFirstCreditUnionLogo.png";
import CentralBankLogo from "./assets/CentralBank/CentralBankLogo.jpeg";
import AccioDataLogo from "./assets/AccioData/AccioDataLogo.png";
import GreatCanadianEntertainmentLogo from "./assets/GreatCanadianEntertainment/GreatCanadianEntertainmentLogo.png";
import WestcorLogo from "./assets/Westcor/WestcorLogo.svg";
import VerizonLogo from "./assets/Verizon/VerizonLogo.png";
import DigitalDealerInnovationsLogo from "./assets/DigitalDealerInnovations/DigitalDealerInnovationsLogo.png";
import DillardsLogo from "./assets/Dillards/DillardsLogo.png";
import TrustdLogo from "./assets/Trustd/TrustdLogo.svg";
import BankOfAmericaLogo from "./assets/BankOfAmerica/BankOfAmericaLogo.svg";
import UownLogo from "./assets/Uown/UownLogo.svg";
import WellsFargoLogo from "./assets/WellsFargo/WellsFargoLogo.png";
import ConnsLogo from "./assets/Conns/ConnsLogo.svg";
import RentACenterLogo from "./assets/RentACenter/RentACenterLogo.png";
import ArcherLogo from "./assets/Archer/ArcherLogo.png";
import ElDoradoFurnitureLogo from "./assets/ElDoradoFurniture/ElDoradoFurnitureLogo.png";
import CabelasLogo from "./assets/Cabelas/CabelasLogo.png";
import LiftedHoldingsLogo from "./assets/LiftedHoldings/LiftedHoldingsLogo.png";
import SalesAssistLogo from "./assets/SalesAssist/SalesAssistLogo.svg";
import BreadFinancialLogo from "./assets/BreadFinancial/BreadFinancialLogo.png";
import RegionsLogo from "./assets/Regions/RegionsLogo.svg";
import FifthThirdLogo from "./assets/FifthThird/FifthThirdLogo.svg";
import OlaSurveysLogo from "./assets/OlaSurveys/OlaSurveysLogo.svg";
import ExperianLogo from "./assets/Experian/ExperianLogo.svg";
import PayRangeLogo from "./assets/PayRange/PayRangeLogo.svg";
import VisaLogo from "./assets/Visa/VisaLogo.png";
import OneVLogo from "./assets/OneV/OneVLogo.png";
import GSHomeDepotLogo from "./assets/GS-HomeDepot/GS-HomeDepotLogo.png";
import MoneyTreeLogo from "./assets/MoneyTree/MoneyTreeLogo.png";
import JustNotarizedLogo from "./assets/JustNotarized/JustNotarizedLogo.png";
import AmericanRedCrossLogo from "./assets/AmericanRedCross/AmericanRedCrossLogo.png";
import InformativLogo from "./assets/Informativ/InformativLogo.png";
import AOLCommunitiesLogo from "./assets/Yahoo/AOLCommunitiesLogo.svg";
import AcimaLogo from "./assets/Acima/AcimaLogo.png";
import RegionsLandingImage from "./assets/Regions/RegionsLandingImage.png";
import PostUniversityLogo from "./assets/PostUniversity/PostUniversityLogo.png";
import ViveFinancialLogo from "./assets/ViveFinancial/ViveFinancialLogo.svg";
import UHaulLandingImage from "./assets/UHaul/UHaulLandingImage.png";
import KeyBankLandingImage from "./assets/KeyBank/KeyBankLandingImage.png";
import CapitalOneLandingImage from "./assets/CapitalOne/CapitalOneLandingImage.svg";
import NavyFederalCreditUnionLandingImage from "./assets/NavyFederalCreditUnion/NavyFederalCreditUnionLandingImage.svg";
import TrustdLandingImage from "./assets/Trustd/TrustdLandingImage.svg";
import AmericanRedCrossLandingImage from "./assets/AmericanRedCross/AmericanRedCrossLandingImage.png";
import InformativLandingImage from "./assets/Informativ/InformativLandingImage.png";
import WestcorBackgroundImage from "./assets/Westcor/WestcorBackgroundImage.png";
import YahooEULandingPage from "./pages/custom/Yahoo/YahooEU/YahooEULandingPage.tsx";
import YahooEUCompletePage from "./pages/custom/Yahoo/YahooEU/YahooEUCompletePage.tsx";
import YahooUSCompletePage from "./pages/custom/Yahoo/YahooUS/YahooUSCompletePage.tsx";
import YahooUSLandingPage from "./pages/custom/Yahoo/YahooUS/YahooUSLandingPage.tsx";
import TruistLandingPage from "./pages/custom/Truist/TruistLandingPage.tsx";
import TruistCompletePage from "./pages/custom/Truist/TruistCompletePage.tsx";
import FirstAmericanTitleLanding from "./pages/custom/FirstAmericanTitle/FirstAmericanTitleLanding.tsx";
import FirstAmericanTitleComplete from "./pages/custom/FirstAmericanTitle/FirstAmericanTitleComplete.tsx";
import YahooEUPassportCapture from "./pages/custom/Yahoo/YahooEU/YahooEUPassportCapture.tsx";
import DomaLanding from "./pages/custom/Doma/DomaLanding.tsx";
import DomaComplete from "./pages/custom/Doma/DomaComplete.tsx";
import FlexitiLanding from "./pages/custom/Flexiti/FlexitiLanding.tsx";
import EarlyWarningLandingPage from "./pages/custom/EarlyWarning/EarlyWarningLandingPage.tsx";
import AmericanFirstFinanceLandingPage from "./pages/custom/AmericanFirstFinance/AmericanFirstFinanceLandingPage.tsx";
import TransUnionLandingPage from "./pages/custom/TransUnion/TransUnionLandingPage.tsx";
import GenericCompanyLandingPage from "./pages/custom/GenericCompanyLandingPage.tsx";
import CommissionBeforeClosingLandingPage from "./pages/custom/CommissionBeforeClosing/CommissionBeforeClosingLandingPage.tsx";
import AcmeLogo from "./assets/Acme/AcmeLogo.svg";
import AcmeLandingPage from "./pages/custom/Acme/AcmeLandingPage.tsx";
import AcmeCompletePage from "./pages/custom/Acme/AcmeCompletePage.tsx";
import AcmeResultsPage from "./pages/custom/Acme/AcmeResultsPage.tsx";
import GreatCanadianEntertainmentLandingPage from "./pages/custom/GreatCanadianEntertainment/GreatCanadianEntertainmentLandingPage.tsx";
import WestcorLandingPage from "./pages/custom/Westcor/WestcorLandingPage.tsx";
import FifthThirdLandingPage from "./pages/custom/FifthThird/FifthThirdLandingPage.tsx";
import IDNDemoLandingPage from "./pages/custom/IDNDemo/IDNDemoLandingPage.tsx";
import TruistBiometricsPageContents from "./pages/custom/Truist/TruistBiometricsPage.tsx";
import CertifIDLandingPage from "./pages/custom/CertifID/CertifIDLandingPage.tsx";
import CertifIDBiometricsPageContents from "./pages/custom/CertifID/CertifIDBiometricsPageContents.tsx";
import CertifIDCameraPermissionsDeniedPage from "./pages/custom/CertifID/CertifIDCameraPermissionsDeniedPage.tsx";
import CertifIDCompletePage from "./pages/custom/CertifID/CertifIDCompletePage.tsx";
import TruistTokenTimeout from "./pages/custom/Truist/TruistTokenTimeout.tsx";
import TruistErrorPage from "./pages/custom/Truist/TruistErrorPage.tsx";
import TruistBiometricsDeniedPage from "./pages/custom/Truist/TruistBiometricsDeniedPage.tsx";
import MoneyTreeLandingPage from "./pages/custom/MoneyTree/MoneyTreeLandingPage.tsx";
import MoneyTreeCompletePage from "./pages/custom/MoneyTree/MoneyTreeCompletePage.tsx";
import MoneyTreeErrorPage from "./pages/custom/MoneyTree/MoneyTreeErrorPage.tsx";
import MoneyTreeTimeoutPage from "./pages/custom/MoneyTree/MoneyTreeTimeoutPage.tsx";
import BreadFinancialErrorPage from "./pages/custom/BreadFinancial/BreadFinancialErrorPage.tsx";
import BreadFinancialTimeoutPage from "./pages/custom/BreadFinancial/BreadFinancialTimeoutPage.tsx";
import AARPLandingPage from "./pages/custom/AARP/AARPLandingPage.tsx";
import AOLCommunitiesLandingPage from "./pages/custom/Yahoo/AOLCommunities/AOLCommunitiesLandingPage.tsx";
import AOLCommunitiesCompletePage from "./pages/custom/Yahoo/AOLCommunities/AOLCommunitiesCompletePage.tsx";
import AolCommunitiesTimeoutPage from "./pages/custom/Yahoo/AOLCommunities/AOLCommunitiesTimeoutPage.tsx";
import AOLCommunitiesCameraDeniedPage from "./pages/custom/Yahoo/AOLCommunities/AOLCommunitiesCameraDeniedPage.tsx";
import AOLCommunitiesErrorPage from "./pages/custom/Yahoo/AOLCommunities/AOLCommunitiesErrorPage.tsx";
import AOLCommunitiesFourZeroFourPage from "./pages/custom/Yahoo/AOLCommunities/AOLCommunitiesFourZeroFourPage.tsx";
import AOLCommunitiesBiometricsDeniedPage from "./pages/custom/Yahoo/AOLCommunities/AOLCommunitiesBiometricsDeniedPage.tsx";
import AcimaLandingPage from "./pages/custom/Acima/AcimaLandingPage.tsx";
import AcimaCameraDeniedPage from "./pages/custom/Acima/AcimaCameraDeniedPage.tsx";
import AcimaBiometricsPage from "./pages/custom/Acima/AcimaBiometricsPage.tsx";
import AcimaCompletePage from "./pages/custom/Acima/AcimaCompletePage.tsx";
import AcimaBiometricsDeniedPage from "./pages/custom/Acima/AcimaBiometricsDeniedPage.tsx";
import CertifIDImagePreview from "./pages/custom/CertifID/CertifIDImagePreview.tsx";
import ViveFinancialLandingPage from "./pages/custom/ViveFinancial/ViveFinancialLandingPage.tsx";
import RentACenterLandingPage from "./pages/custom/RentACenter/RentACenterLandingPage.tsx";
import RentACenterBiometricsPage from "./pages/custom/RentACenter/RentACenterBiometricsPage.tsx";
import RentACenterCompletePage from "./pages/custom/RentACenter/RentACenterCompletePage.tsx";
import RentACenterErrorPage from "./pages/custom/RentACenter/RentACenterErrorPage.tsx";
import RegionsCompletePage from "./pages/custom/Regions/RegionsCompletePage.tsx";
import RegionsErrorPage from "./pages/custom/Regions/RegionsErrorPage.tsx";
import RegionsTimeoutPage from "./pages/custom/Regions/RegionsTimeoutPage.tsx";

interface BackgroundProps {
    backgroundImage?: string;
    backgroundSize?: string;
    backgroundRepeat?: string;
    backgroundPosition?: string;
    backgroundColor?: string;
}
type CompanyMap = {
    [key: string]: {
        logo?: string;
        css: string;
        additionalRoutes?: { route: string; element: React.ReactNode }[];
        landingImages?: string[];
        hideDefaultLanding?: boolean;
        landingComponent?: React.ReactNode;
        frontCaptureComponent?: React.ReactNode;
        barcodeCaptureComponent?: React.ReactNode;
        hideCountdownTimer?: boolean;
        completeComponent?: React.ReactNode;
        cameraDeniedComponent?: React.ReactNode;
        biometricsDeniedComponent?: React.ReactNode;
        fourZeroFourPage?: React.ReactNode;
        customBackground?: string;
        backgroundProps?: BackgroundProps;
        hideBottomBiometricsLink?: boolean;
        biometricsPolicy?: React.ReactNode;
        ariaLogo?: string;
        ariaLandingImg?: string;
        tokenTimeoutPage?: React.ReactNode;
        errorPage?: React.ReactNode;
        biometricsContinueBtnKey?: string;
        biometricsDeclineBtnKey?: string;
        secondButtonVariant?: string;
        biometricsDenyUrl?: string;
        completeRedirectTimeout?: number;
        skipLanding?: boolean;
        imagePreviewScreen?: React.ReactNode;
    };
};

const isSimData = window.__RUNTIME_CONFIG__.REACT_APP_SIMULATED;

export const companyMap: CompanyMap = {
    default: {
        logo: IntellicheckLogo,
        css: "css/intellicheck.css",
        ariaLogo: "Intellicheck Logo",
        ariaLandingImg: "Man verifying identity on phone",
    },
    idndemo: {
        logo: IntellicheckLogo,
        landingComponent: <IDNDemoLandingPage />,
        hideDefaultLanding: true,
        hideCountdownTimer: true,
        css: "css/intellicheck.css",
        ariaLogo: "Intellicheck Logo",
    },
    acme: {
        logo: isSimData ? IntellicheckLogo : AcmeLogo,
        css: "css/intellicheck.css",
        additionalRoutes: [{ route: "/results", element: <AcmeResultsPage /> }],
        landingComponent: <AcmeLandingPage />,
        hideDefaultLanding: true,
        hideCountdownTimer: true,
        completeComponent: <AcmeCompletePage />,
        ariaLogo: "Acme Logo",
    },
    sofi: {
        logo: SoFiLogo,
        css: "css/sofi.css",
        landingComponent: <GenericCompanyLandingPage companyName='Sofi' />,
        hideCountdownTimer: true,
        ariaLogo: "Sofi Logo",
    },
    greensky: {
        logo: GreenskyLogo,
        css: "css/greensky.css",
        landingComponent: <GenericCompanyLandingPage companyName='Greensky' />,
        ariaLogo: "Greensky + Intellicheck Logo",
    },
    "gs-homedepot": {
        logo: GSHomeDepotLogo,
        css: "css/gs-homedepot.css",
        landingComponent: <GenericCompanyLandingPage companyName='The Home Depot' />,
        ariaLogo: "The Home Depot + Intellicheck Logo",
    },
    uhaul: {
        logo: UHaulLogo,
        css: "css/uhaul.css",
        landingImages: [UHaulLandingImage],
        ariaLogo: "Uhaul Logo",
    },
    yahooeu: {
        logo: YahooLogo,
        css: "css/yahoo.css",
        landingComponent: <YahooEULandingPage />,
        frontCaptureComponent: <YahooEUPassportCapture />,
        hideDefaultLanding: true,
        completeComponent: <YahooEUCompletePage />,
        hideCountdownTimer: true,
        ariaLogo: "Yahoo/AOL Logo",
    },
    yahoous: {
        logo: YahooLogo,
        css: "css/yahoo.css",
        landingComponent: <YahooUSLandingPage />,
        hideDefaultLanding: true,
        completeComponent: <YahooUSCompletePage />,
        hideCountdownTimer: true,
        ariaLogo: "Yahoo/AOL Logo",
    },
    yahoomail: {
        logo: YahooLogo,
        css: "css/yahoo.css",
        landingComponent: <YahooUSLandingPage />,
        hideDefaultLanding: true,
        completeComponent: <YahooUSCompletePage />,
        hideCountdownTimer: true,
        ariaLogo: "Yahoo/AOL Logo",
    },
    "aol-communities": {
        logo: AOLCommunitiesLogo,
        css: "css/aolcommunities.css",
        hideDefaultLanding: true,
        landingComponent: <AOLCommunitiesLandingPage />,
        completeComponent: <AOLCommunitiesCompletePage />,
        tokenTimeoutPage: <AolCommunitiesTimeoutPage />,
        cameraDeniedComponent: <AOLCommunitiesCameraDeniedPage />,
        fourZeroFourPage: <AOLCommunitiesFourZeroFourPage />,
        biometricsDeniedComponent: <AOLCommunitiesBiometricsDeniedPage />,
        errorPage: <AOLCommunitiesErrorPage />,
        customBackground: "#FFFFFF",
        ariaLogo: "AOL Logo",
    },
    truist: {
        logo: TruistLogo,
        css: "css/truist.css",
        landingComponent: <TruistLandingPage />,
        hideDefaultLanding: true,
        completeComponent: <TruistCompletePage />,
        biometricsPolicy: <TruistBiometricsPageContents />,
        tokenTimeoutPage: <TruistTokenTimeout />,
        biometricsDeniedComponent: <TruistBiometricsDeniedPage />,
        errorPage: <TruistErrorPage />,
        hideBottomBiometricsLink: true,
        ariaLogo: "Truist Logo",
        customBackground: "#FFFFFF",
    },
    firstamericantitle: {
        logo: FirstAmericanLogo,
        css: "css/firstamericantitle.css",
        landingComponent: <FirstAmericanTitleLanding />,
        completeComponent: <FirstAmericanTitleComplete />,
        hideCountdownTimer: true,
        ariaLogo: "First American Title Logo",
    },
    keybank: {
        logo: KeyBankLogo,
        css: "css/keybank.css",
        landingImages: [KeyBankLandingImage],
        ariaLogo: "Keybank Logo",
        ariaLandingImg: "Woman walking with phone in hand",
    },
    hidglobal: {
        logo: HIDGlobalLogo,
        css: "css/hidglobal.css",
        landingComponent: <GenericCompanyLandingPage companyName='HID Global' />,
        hideCountdownTimer: true,
        ariaLogo: "HID Global Logo",
    },
    doma: {
        logo: DomaLogo,
        css: "css/doma.css",
        landingComponent: <DomaLanding />,
        completeComponent: <DomaComplete />,
        hideCountdownTimer: true,
        ariaLogo: "Doma Logo",
    },
    flexiti: {
        logo: FlexitiLogo,
        css: "css/flexiti.css",
        landingComponent: <FlexitiLanding />,
        hideCountdownTimer: true,
        ariaLogo: "Flexiti Logo",
    },
    ubsbank: {
        logo: UBSBankLogo,
        css: "css/ubsbank.css",
        landingComponent: <GenericCompanyLandingPage companyName='UBS Bank' />,
        hideCountdownTimer: true,
        ariaLogo: "UBS Bank Logo",
    },
    dcbank: {
        logo: DCBankLogo,
        css: "css/dcbank.css",
        landingComponent: <GenericCompanyLandingPage companyName='DC Bank' />,
        hideCountdownTimer: true,
        ariaLogo: "DC Bank Logo",
    },
    pncbank: {
        logo: PNCBankLogo,
        css: "css/pncbank.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='PNC Bank' />,
        ariaLogo: "PNC Bank Logo",
    },
    earlywarning: {
        logo: EarlyWarningLogo,
        css: "css/earlywarning.css",
        hideCountdownTimer: true,
        landingComponent: <EarlyWarningLandingPage />,
        ariaLogo: "Early Warning Logo",
    },
    americanfirstfinance: {
        logo: AmericanFirstFinanceLogo,
        css: "css/americanfirstfinance.css",
        hideCountdownTimer: true,
        landingComponent: <AmericanFirstFinanceLandingPage />,
        ariaLogo: "American First Finance Logo",
    },
    transunion: {
        logo: TransUnionLogo,
        css: "css/transunion.css",
        hideCountdownTimer: true,
        landingComponent: <TransUnionLandingPage />,
        ariaLogo: "TransUnion Logo",
    },
    capitalone: {
        logo: CapitalOneLogo,
        css: "css/capitalone.css",
        hideCountdownTimer: true,
        landingImages: [CapitalOneLandingImage],
        ariaLogo: "Capital One Logo",
        ariaLandingImg: "Hands holding a tablet surrounded by confetti",
    },
    bellmobility: {
        logo: BellMobilityLogo,
        css: "css/bellmobility.css",
        landingComponent: <GenericCompanyLandingPage companyName='Bell Mobility' />,
        hideCountdownTimer: true,
        ariaLogo: "Bell Mobility Logo",
    },
    citibank: {
        logo: CitiBankLogo,
        css: "css/citibank.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='Citibank' />,
        ariaLogo: "Citibank Logo",
    },
    bestbuy: {
        logo: BestBuyLogo,
        css: "css/bestbuy.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='Best Buy' />,
        ariaLogo: "Best Buy Logo",
    },
    progressiveleasing: {
        logo: ProgressiveLeasingLogo,
        css: "css/progressiveleasing.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='Progressive Leasing' />,
        ariaLogo: "Progressive Leasing Logo",
    },
    sevenhundredcredit: {
        logo: SevenHundredCreditLogo,
        css: "css/sevenhundredcredit.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='700Credit' />,
        ariaLogo: "700Credit Logo",
    },
    aarp: {
        logo: IntellicheckLogo,
        css: "css/intellicheck.css",
        hideCountdownTimer: true,
        landingComponent: <AARPLandingPage />,
        ariaLogo: "Intellicheck Logo",
    },
    meta: {
        logo: MetaLogo,
        css: "css/meta.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='Meta' />,
        ariaLogo: "Meta Logo",
    },
    boeingemployeecreditunion: {
        logo: BoeingEmployeeCreditUnionLogo,
        css: "css/boeingemployeecreditunion.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='Boeing Employee Credit Union' />,
        ariaLogo: "Boeing Employee Credit Union Logo",
    },
    navyfederalcreditunion: {
        logo: NavyFederalCreditUnionLogo,
        css: "css/navyfederalcreditunion.css",
        hideCountdownTimer: true,
        landingImages: [NavyFederalCreditUnionLandingImage],
        ariaLogo: "Navy Federal Credit Union Logo",
        ariaLandingImg: "A phone with several credit cards aligned like a fan to the right of it",
    },
    commissionbeforeclosing: {
        logo: CommissionBeforeClosingLogo,
        css: "css/commissionbeforeclosing.css",
        hideCountdownTimer: true,
        landingComponent: <CommissionBeforeClosingLandingPage />,
        ariaLogo: "Commission Before Closing Logo",
    },
    americafirstcreditunion: {
        logo: AmericaFirstCreditUnionLogo,
        css: "css/americafirstcreditunion.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='America First Credit Union' />,
        ariaLogo: "America First Credit Union Logo",
    },
    centralbank: {
        logo: CentralBankLogo,
        css: "css/centralbank.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='Central Bank' />,
        ariaLogo: "Central Bank Logo",
    },
    acciodata: {
        logo: AccioDataLogo,
        css: "css/acciodata.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='Accio Data' />,
        ariaLogo: "Accio Data Logo",
    },
    greatcanadianentertainment: {
        logo: GreatCanadianEntertainmentLogo,
        css: "css/greatcanadianentertainment.css",
        hideCountdownTimer: true,
        landingComponent: <GreatCanadianEntertainmentLandingPage />,
        ariaLogo: "Great Canadian Entertainment Logo",
    },
    westcor: {
        logo: WestcorLogo,
        css: "css/westcor.css",
        landingComponent: <WestcorLandingPage />,
        backgroundProps: {
            backgroundImage: `url(${WestcorBackgroundImage})`,
            backgroundSize: "contain",
            backgroundRepeat: "repeat",
            backgroundPosition: "center",
        },
        ariaLogo: "Westcor Logo",
    },
    verizon: {
        logo: VerizonLogo,
        css: "css/verizon.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='Verizon' />,
        ariaLogo: "Verizon Logo",
    },
    digitaldealerinnovations: {
        logo: DigitalDealerInnovationsLogo,
        css: "css/digitaldealerinnovations.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='AcceleFi' />,
        ariaLogo: "Digital Dealer Innovations Logo",
    },
    trustd: {
        logo: TrustdLogo,
        css: "css/trustd.css",
        hideCountdownTimer: true,
        landingImages: [TrustdLandingImage],
        ariaLogo: "Trustd Logo",
        ariaLandingImg: "A diamond layout showing various objects, such as a home, car, and technology along the diamond path",
    },
    bankofamerica: {
        logo: BankOfAmericaLogo,
        css: "css/bankofamerica.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='Bank of America' />,
        ariaLogo: "Bank of America Logo",
    },
    uown: {
        logo: UownLogo,
        css: "css/uown.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='Uown' />,
        ariaLogo: "Uown Logo",
    },
    dillards: {
        logo: DillardsLogo,
        css: "css/dillards.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName={`Dillard's`} />,
        ariaLogo: "Dillard's Logo",
    },
    wellsfargo: {
        logo: WellsFargoLogo,
        css: "css/wellsfargo.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='Wells Fargo' />,
        ariaLogo: "Wells Fargo Logo",
    },
    conns: {
        logo: ConnsLogo,
        css: "css/conns.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='Conn&#39;s HomePlus' />,
        ariaLogo: "Conn's HomePlus Logo",
    },
    rentacenter: {
        logo: RentACenterLogo,
        css: "css/rentacenter.css",
        customBackground: "#FFFFFF",
        landingComponent: <RentACenterLandingPage />,
        hideDefaultLanding: true,
        biometricsPolicy: <RentACenterBiometricsPage />,
        completeComponent: <RentACenterCompletePage />,
        errorPage: <RentACenterErrorPage />,
        ariaLogo: "Rent-A-Center Logo",
    },
    archer: {
        logo: ArcherLogo,
        css: "css/archer.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='Archer' />,
        ariaLogo: "Archer Logo",
    },
    eldoradofurniture: {
        logo: ElDoradoFurnitureLogo,
        css: "css/eldoradofurniture.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='El Dorado Furniture' />,
        ariaLogo: "El Dorado Furniture Logo",
    },
    cabelas: {
        logo: CabelasLogo,
        css: "css/cabelas.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='Cabela&#39;s' />,
        ariaLogo: "Cabela's Logo",
    },
    liftedholdings: {
        logo: LiftedHoldingsLogo,
        css: "css/liftedholdings.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='AgeVend' />,
        ariaLogo: "Lifted Holdings Logo",
    },
    salesassist: {
        logo: SalesAssistLogo,
        css: "css/salesassist.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='SalesAssist' />,
        ariaLogo: "Sales Assist Logo",
    },
    breadfinancial: {
        logo: BreadFinancialLogo,
        css: "css/breadfinancial.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='Bread Financial' />,
        tokenTimeoutPage: <BreadFinancialTimeoutPage />,
        errorPage: <BreadFinancialErrorPage />,
        ariaLogo: "Bread Financial Logo",
        skipLanding: true,
    },
    regions: {
        logo: RegionsLogo,
        css: "css/regions.css",
        customBackground: "#FFFFFF",
        landingImages: [RegionsLandingImage],
        completeComponent: <RegionsCompletePage />,
        errorPage: <RegionsErrorPage />,
        tokenTimeoutPage: <RegionsTimeoutPage />,
        ariaLogo: "Regions Logo",
    },
    fifththird: {
        logo: FifthThirdLogo,
        css: "css/fifththird.css",
        hideCountdownTimer: true,
        landingComponent: <FifthThirdLandingPage />,
        ariaLogo: "Fifth-Third Bank Logo",
    },
    olasurveys: {
        logo: OlaSurveysLogo,
        css: "css/olasurveys.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='Survey Diem' />,
        ariaLogo: "Ola Surveys Logo",
    },
    experian: {
        logo: ExperianLogo,
        css: "css/experian.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='Experian' />,
        ariaLogo: "Experian Logo",
    },
    certifid: {
        css: "css/certifid.css",
        landingComponent: <CertifIDLandingPage />,
        completeComponent: <CertifIDCompletePage />,
        hideDefaultLanding: true,
        hideCountdownTimer: true,
        secondButtonVariant: "outline-secondary",
        customBackground: "#FFFFFF",
        hideBottomBiometricsLink: true,
        biometricsPolicy: <CertifIDBiometricsPageContents />,
        biometricsContinueBtnKey: "certifid.continueButton",
        biometricsDeclineBtnKey: "certifid.declineAndExit",
        completeRedirectTimeout: 1500,
        biometricsDenyUrl: "https://portal.certifid.com/client/identity/warn/biometric",
        cameraDeniedComponent: <CertifIDCameraPermissionsDeniedPage />,
        imagePreviewScreen: <CertifIDImagePreview />,
    },
    visa: {
        logo: VisaLogo,
        css: "css/visa.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='Visa' />,
        ariaLogo: "Visa Logo",
    },
    onev: {
        logo: OneVLogo,
        css: "css/onev.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='OneV' />,
        customBackground: "#FFFFFF",
        ariaLogo: "OneV Logo",
    },
    payrange: {
        logo: PayRangeLogo,
        css: "css/payrange.css",
        hideCountdownTimer: true,
        landingComponent: <GenericCompanyLandingPage companyName='PayRange' />,
        ariaLogo: "PayRange Logo",
    },
    moneytree: {
        logo: MoneyTreeLogo,
        css: "css/moneytree.css",
        landingComponent: <MoneyTreeLandingPage />,
        completeComponent: <MoneyTreeCompletePage />,
        errorPage: <MoneyTreeErrorPage />,
        tokenTimeoutPage: <MoneyTreeTimeoutPage />,
        customBackground: "#FFFFFF",
        ariaLogo: "Money Tree Logo",
    },
    justnotarized: {
        logo: JustNotarizedLogo,
        css: "css/justnotarized.css",
        landingComponent: <GenericCompanyLandingPage companyName='JustNotarized' />,
        ariaLogo: "JustNotarized Logo",
    },
    americanredcross: {
        logo: AmericanRedCrossLogo,
        css: "css/americanredcross.css",
        landingImages: [AmericanRedCrossLandingImage],
        ariaLogo: "American Red Cross Logo",
    },
    informativ: {
        logo: InformativLogo,
        css: "css/informativ.css",
        landingImages: [InformativLandingImage],
        ariaLogo: "Informativ Logo",
    },
    acima: {
        logo: AcimaLogo,
        css: "css/acima.css",
        landingComponent: <AcimaLandingPage />,
        hideDefaultLanding: true,
        customBackground: "#FFFFFF",
        cameraDeniedComponent: <AcimaCameraDeniedPage />,
        biometricsPolicy: <AcimaBiometricsPage />,
        biometricsDeniedComponent: <AcimaBiometricsDeniedPage />,
        completeComponent: <AcimaCompletePage />,
        ariaLogo: "Acima logo",
    },
    "post-university": {
        logo: PostUniversityLogo,
        css: "css/postuniversity.css",
        customBackground: "#FFFFFF",
        ariaLogo: "Post University Logo",
    },
    "vive-financial": {
        logo: ViveFinancialLogo,
        css: "css/vivefinancial.css",
        landingComponent: <ViveFinancialLandingPage />,
        customBackground: "#FFFFFF",
        ariaLogo: "Vive Financial logo",
    },
};
