import Background from "../../../components/Background/Background.tsx";
import LogoAndTimer from "../../../components/LogoAndTimer/LogoAndTimer.tsx";
import React from "react";
import { useTranslation } from "react-i18next";

const RegionsTimeoutPage = () => {
  const { t } = useTranslation();
  
  return (
    <Background>
      <LogoAndTimer />
        <p className='mt-3 text-center'>{t("regions.timeout")}</p>
    </Background>
  );
};

export default RegionsTimeoutPage;
