import React, { useEffect } from "react";
import DeviceFingerprint from "node_js_ipqs_device_tracker";
import { useIngestIpqsTrackerIdMutation, useLogEventMutation } from "../../api/api.ts";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store.ts";
import CustomerJourneyCodes from "../../assets/CustomerJourneyCodes.json";
import createLogEventBody from "../../utils/createLogEventBody.js";

export type OwnProps = {
    transactionId: string;
};

const IpqsTracker: React.FC<OwnProps> = ({ transactionId }) => {
    const [logEvent] = useLogEventMutation();
    const [ingestIpqsTrackerId] = useIngestIpqsTrackerIdMutation();
    const hasProcessedIpqs = sessionStorage.getItem("hasInitializedIPQS");
    const currRouterVersion = useSelector((state: RootState) => state.app.routerVersion);
    const apiVersion = window.__RUNTIME_CONFIG__.REACT_APP_API_VERSION;
    const ipqsDebugLevel = process.env.REACT_APP_IPQS_DEBUG_LEVEL ? Number(process.env.REACT_APP_IPQS_DEBUG_LEVEL) : 0;

    const handleIPQSResult = (result: any) => {
        // immediately log the result object
        if (apiVersion === "1") {
            logEvent(createLogEventBody(CustomerJourneyCodes.landing.submitIpqsTrackerIdApiCall.status, JSON.stringify({ result })));
        }

        // if legacy, use transactionId as the ipqsTrackerId, otherwise, use the requestId from IPQS
        // on the backend, legacy calls IPQS with the ipqsTrackerId and the IPQS list api
        // non-legacy uses the IPQS postBack API that requires the request_id
        if (apiVersion === "0") {
            const ipqsPayload = { ipqsTrackerId: transactionId, userAgent: navigator.userAgent };
            ingestIpqsTrackerId(ipqsPayload);
            sessionStorage.setItem("hasInitializedIPQS", "true");
        } else {
            let requestId;
            if (result.request_id) {
                requestId = result.request_id;
                const ipqsPayload = { ipqsTrackerId: requestId, userAgent: navigator.userAgent };
                ingestIpqsTrackerId(ipqsPayload);
                sessionStorage.setItem("hasInitializedIPQS", "true");
            }
        }
    };

    const handleIPQSFailure = (result: any) => {
        try {
            const executionLogs = DeviceFingerprint.FetchExecutionLogs();
            logEvent(
                createLogEventBody(
                    CustomerJourneyCodes.landing.ipqsInitFailure.status,
                    JSON.stringify({
                        result,
                        executionLogs,
                    }),
                ),
            );
        } catch (e) {
            logEvent(
                createLogEventBody(
                    CustomerJourneyCodes.landing.ipqsInitEventException.status,
                    CustomerJourneyCodes.landing.ipqsInitEventException.action,
                ),
            );
        }
    };

    /** IPQS Ingestion */
    useEffect(() => {
        logEvent(
            createLogEventBody(
                CustomerJourneyCodes.landing.ipqsTrackerInitialization.status,
                `transactionID: ${transactionId} hasProcessedIpqs: ${hasProcessedIpqs}`,
            ),
        );
        if (hasProcessedIpqs !== "true") {
            const ipqsKey = window.__RUNTIME_CONFIG__.REACT_APP_IPQS_KEY || "";
            logEvent(
                createLogEventBody(CustomerJourneyCodes.landing.trackerInitialized.status, CustomerJourneyCodes.landing.trackerInitialized.action),
            );
            DeviceFingerprint.initializeScriptAsync(ipqsKey)
                .then(async () => {
                    DeviceFingerprint.SetDebugLevel(ipqsDebugLevel);
                    DeviceFingerprint.Store("transactionID", transactionId);
                    DeviceFingerprint.AfterResult(handleIPQSResult as () => void);
                    DeviceFingerprint.AfterFailure(handleIPQSFailure as () => void);
                    DeviceFingerprint.Init();
                })
                .catch((err) => {
                    logEvent(createLogEventBody(CustomerJourneyCodes.landing.ipqsInitFailure.status, err));
                });
        }
    }, [currRouterVersion, hasProcessedIpqs]);

    return <></>;
};

export default IpqsTracker;
