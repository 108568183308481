import React, { useEffect, useState } from "react";
import Background from "../../components/Background/Background.tsx";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CustJourneyCodes from "../../assets/CustomerJourneyCodes.json";
import LogoAndTimer from "../../components/LogoAndTimer/LogoAndTimer.tsx";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store.ts";
import { Link } from "react-router-dom";
import { getCompanyConfig } from "../../utils/getCompanyConfig.ts";
import { useLogEventMutation } from "../../api/api.ts";
import createLogEventBody from "../../utils/createLogEventBody.js";

const CameraPermissionsDenied = () => {
    const { t } = useTranslation();
    const user = useSelector((state: RootState) => state.user);
    const errorRedirectUrl = useSelector((state: RootState) => state.user.errorRedirectUrl);
    const { token, routerVersion, language } = user;
    const cameraDeniedComponent = getCompanyConfig("cameraDeniedComponent");
    const sessionStorageErrorUrl = sessionStorage.getItem("errorRedirectUrl");
    const errorLink = errorRedirectUrl ? errorRedirectUrl : sessionStorageErrorUrl !== "undefined" ? sessionStorageErrorUrl : null;

    const [logEvent, logEventResponse] = useLogEventMutation();
    const [hasFiredLogEvent, setHasFiredLogEvent] = useState(false);

    // Fire status message when user navigates to this page
    useEffect(() => {
        logEvent(createLogEventBody(CustJourneyCodes.errors.captureCameraAccessDenied.navToPage.status));
    }, []);

    useEffect(() => {
        const sendRedirectLog = async () => {
            try {
                if (logEventResponse.isSuccess && errorLink && !hasFiredLogEvent) {
                    setHasFiredLogEvent(true);
                    logEvent(createLogEventBody(CustJourneyCodes.errors.errorRedirectUrl.status));
                    if (logEventResponse.isSuccess) {
                        window.location.reload();
                        window.location.replace(errorLink);
                    } else if (logEventResponse.isError && !logEventResponse.isSuccess && !logEventResponse.isLoading) {
                        logEvent(createLogEventBody(CustJourneyCodes.errors.errorRedirectUrlFailure.status));
                    }
                }
            } catch {
                logEvent(createLogEventBody(CustJourneyCodes.errors.errorRedirectUrlFailure.status));
            }
        };
        sendRedirectLog();
    }, [logEventResponse.isSuccess, logEventResponse.isError, errorLink, hasFiredLogEvent]);

    return (
        <>
            {!errorRedirectUrl && (
                <Background>
                    {cameraDeniedComponent ? (
                        cameraDeniedComponent
                    ) : (
                        <>
                            <LogoAndTimer />
                            <Row className='vh-100 mt-3 justify-content-between'>
                                <Col xs={12} className='mb-5'>
                                    <h1 className='mb-3 text-primary'>{t("cameraPermissionsDeniedPage.question")}</h1>
                                    <p className='mb-0'>{t("cameraPermissionsDeniedPage.description")}</p>
                                    <ul role='list'>
                                        <li role='listitem' aria-label='To scan: Select the Back button and allow access to your camera.'>
                                            <span className='fw-bold'>{t("cameraPermissionsDeniedPage.verify")}: </span>
                                            {t("cameraPermissionsDeniedPage.toVerify1")}
                                            <b>{t("cameraPermissionsDeniedPage.toVerify2")}</b>
                                            {t("cameraPermissionsDeniedPage.toVerify3")}
                                        </li>
                                        <li role='listitem' aria-label='To exit: Close this tab.'>
                                            <p>
                                                <span className='fw-bold'>{t("cameraPermissionsDeniedPage.exit")}: </span>
                                                {t("cameraPermissionsDeniedPage.toExit")}
                                            </p>
                                        </li>
                                    </ul>
                                </Col>
                                <Col xs={12} className='mt-4'>
                                    <Link reloadDocument to={`/?token=${token}&version=${routerVersion}&language=${language}&reload=true`}>
                                        <Button className='w-100'>{t("cameraPermissionsDeniedPage.back")}</Button>
                                    </Link>
                                </Col>
                            </Row>
                        </>
                    )}
                </Background>
            )}
        </>
    );
};

export default CameraPermissionsDenied;
